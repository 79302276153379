
.index li { 
	font-size: 1.2rem;
}

.hero { 
	width: 100%;
	margin-top: 4rem;
	margin-bottom: 1rem;
}

/**
 * Index lists shoudl be denser, like the back of a book
 */
.index-subpath { 
	width: 45%;
	display: inline-block;
	text-align: left;
	vertical-align: top;
	margin-bottom: 2rem;
}