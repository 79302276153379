
/**
 * Illuminate links that are not found
 */

.link-not-found {
	background-color: #eee;
	padding: 0.25rem;
}

.link { 
}

/*
Publication Styling */
a > h4 {
  font-size: 100%;
  font-weight: 200;
  text-align: right;
  color: grey;
  font-style: italic;
}

