
pre {
  background-color: #FEFFD6;
}


/** Flatmap Syntax Highlighting */

.flatmap-config {
  font-weight: 700;
  background-color: #FEFFD6;
  padding: 0.25rem;
}
