.screenshot-container { 
	width: 45%;
	display: inline-block;
	padding: 0.5rem;
	vertical-align: top;
}

.screenshot-thumbnail { 
	width: 100%;
	min-width: 100%;
	border-width: 1px;
	border-style: solid;
	border-color: #666;
}

.screenshot-link {

}

.screenshot-caption { 
	margin-top: 0.5rem;
	font-size: 1.2rem;
	display: inline-block;
	width: 100%;
	text-align: center;
}

.screenshot-error {
	color: #f00;
	padding: 0.5rem;
	margin: 0.5rem;
	background-color: #ddd;
}