
/*
Splash color - Header Bar Override */
.site-header {
  border-top-color: #005677;
  border-bottom-color: #aaad00;
  background-color: #005677;
  color: #ffffff;
}

.site-nav .trigger {
  background-color: #005677;
  margin-bottom: 0.5rem;
}

.site-header .page-link {
  color: #eeeeee !important;
}

.site-header .site-title {
  color: #ffffff !important;
  float: none !important;
}

.site-header .site-tagline {
  margin-left: 1rem;
  color: #eeeeee !important;
  float: none !important;
}

.site-header .site-logo {
  max-height: 4rem;
  margin: 0.5rem;
}

.site-header .site-nav {
  float: none !important;
  line-height: 1.7rem !important;
}

.site-header .nav-wrapper-logo {
  display: inline-block;
  float: left;
}

.site-header .nav-wrapper-inner {
  display: inline-block;
  margin-left: 1rem;
}

.site-footer {
  background-color: #efefef;
}


.wrapper {
  max-width: calc(1200px - (30px * 2));
  width: 100%;
}