/**
 * Search Box Styling
 */

#search-input { 
	font-size: 1.4rem;
    width: 33%;
    padding: 0.5rem;
    margin-top: 0.5rem;
}

#search-results { 
    padding-top: 0.5rem;
    border-style: solid;
    border-color: #005677;
    border-width: 1px;
    margin-left: 0rem;
    background-color: #fff;
    width: 600px;
    position: absolute;
    font-size: 1.2rem;
}

/* highlight first-child in search results, because you can press enter */

#results-container .active {
    background-color: #ddd;

}